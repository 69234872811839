import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Home from "./Home";
import ArticleList from "./ArticleList";
import Article from "./Article";
import Error from "./Error";
import Header from "./Header";
import Footer from "./Footer";
import dataSource from "../dataSource";

export default class App extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        dataSource.refresh(() => {
            this.setState({
                loading: false
            })
        });
    }

    render() {
        return (
            <div id={"app"}>
                <Router>
                    <Header />

                    <main>
                        {!this.state.loading &&
                            <Switch>
                                <Route exact path="/article/:slug">
                                    <Article />
                                </Route>
                                <Route exact path="/article">
                                    <ArticleList category="article" />
                                </Route>
                                <Route exact path="/project/:slug">
                                    <Article />
                                </Route>
                                <Route exact path="/project">
                                    <ArticleList category="project" />
                                </Route>
                                <Route exact path="/">
                                    <Home />
                                </Route>
                                <Route path="*">
                                    <Error msg="404" />
                                </Route>
                            </Switch>
                        }
                    </main>

                    <Footer />
                </Router>
            </div>
        );
    }
}