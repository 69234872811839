import {urlJoin} from "./util";
import slug from "slug";
import _ from "lodash";

const public_url = process.env.PUBLIC_URL.length === 0 ? '/' : process.env.PUBLIC_URL;
const content_url = urlJoin([public_url, "content"]);

class DataItem {
    constructor(data) {
        this.title = data.title;
        this.slug = slug(this.title);
        this.source = "source" in data ? data.source : this.slug;
        this.description = data.description;
        this.urls = data.categories.reduce((urls, category) => {
            urls[category] = urlJoin([public_url, category, this.slug]);
            return urls;
        }, {})
        // this.urls = data.categories.map(category => urlJoin([public_url, category, this.slug]));
        this.src = urlJoin([content_url, this.source, this.source + ".md"]);
        this.prefix = urlJoin([content_url, this.source, "/"]);
        this.img = urlJoin([content_url, this.source, "cover" in data ? data.cover : "cover.jpg"]);
        this.date = new Date(data.date);
        this.tags = data.tags;
        this.filters = data.filters;
        this.categories = data.categories;
    }

    shortDescription(length) {
        return _.truncate(this.description, {
            'length': length,
            'separator': ' '
        })
    }
}

class DataSource {
    constructor() {
        this.dataItems = [];
        this.slugs = {};
    }

    refresh(callback) {
        fetch(urlJoin([content_url, "content.json"]))
            .then(response => response.json())
            .then(rawData => {
                if (!Array.isArray(rawData)) {
                    throw new TypeError("expected an array of data.");
                }

                this.slugs = {};
                let unsortedData = [];

                for (const data of rawData) {
                    let dataItem = new DataItem(data);
                    unsortedData.push(dataItem);
                    this.slugs[dataItem.slug] = dataItem;
                }

                this.dataItems = _.orderBy(unsortedData, ['date'], ['desc']);
            })
            .then(() => callback())
            .catch(error => console.error(error));
    }

    withCategory(category) {
        return this.dataItems.filter(dataItem => dataItem.categories.includes(category))
    }

    forSlug(slug) {
        return this.slugs[slug];
    }

    latestInCategory(category) {
        return this.dataItems.filter(dataItem => dataItem.categories.includes(category))[0];
    }
}

let dataSource = new DataSource();

export default dataSource;