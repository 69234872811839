import React from "react";

export default class Footer extends React.Component {
    render() {
        return (
            <footer id={"footer"}>
                <p>copyright © 2021 Yasin Keyani</p>
                <a className={"github"} href={"https://github.com/ykeyani"} >
                    <span className={"accessibility"}>github</span>
                </a>
                <a className={"stackoverflow"} href={"https://stackoverflow.com/users/1614269/bob"} >
                    <span className={"accessibility"}>stack overflow</span>
                </a>
            </footer>
        );
    }
}