import React from "react";
import {Link} from "react-router-dom";

export default class Header extends React.Component {
    render() {
        return (
            <header id={"header"}>
                <nav>
                    <ul>
                        <li>
                            <Link to="/" id={"home-link"}><span className={"accessibility"}>Yasin Keyani</span></Link>
                        </li>
                        <li>
                            <Link to="/project" id={"projects-link"}><span className={"accessibility"}>Projects</span></Link>
                        </li>
                        <li>
                            <Link to="/article" id={"articles-link"}><span className={"accessibility"}>Articles</span></Link>
                        </li>
                    </ul>
                </nav>
            </header>
        );
    }
}