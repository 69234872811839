import React from "react";
import { withRouter } from "react-router";
import MarkdownView from "./MarkdownView";
import Error from "./Error";
import dataSource from "../dataSource";

class Article extends React.Component {
    constructor(props, context) {
        super(props, context);

        let slug = this.props.match.params.slug;

        this.state = {
            articleItem: dataSource.forSlug(slug)
        }
    }

    render() {
        if (this.state.articleItem === undefined) {
            return (
                    <Error msg="404" />
            );
        }

        return (
            <div id={"article"}>
                <span className={"article-date"}>{this.state.articleItem.date.toLocaleDateString()}</span>
                <h1>{this.state.articleItem.title}</h1>
                <MarkdownView src={this.state.articleItem.src} proxy={this.state.articleItem.prefix} />
            </div>
        );
    }
}

export default withRouter(Article);