import React from "react";
import home from "../content/home.md"
import MarkdownView from "./MarkdownView";
import ArticleStub from "./ArticleStub";
import dataSource from "../dataSource";

export default class Home extends React.Component {
    render() {
        return (
            <div id={"home"}>
                <h1 className={"hidden"}>Yasin Keyani</h1>
                <MarkdownView src={home} />
                <h2>Latest Article</h2>
                <ArticleStub article={dataSource.latestInCategory("article")} category={"article"} />
            </div>
        );
    }
}