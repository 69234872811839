import {Link} from "react-router-dom";
import React from "react";

function ArticleStub(props) {
    const articleItem = props.article;
    const category = props.category;

    return (
        <div className={"article-stub"}>
            <span className={"article-date"}>{articleItem.date.toLocaleDateString()}</span>
            <Link to={articleItem.urls[category]}>
                <h2>
                    {articleItem.title}
                </h2>
            </Link>
            { articleItem.tags.length > 0 &&
                <div className={"tag-container"}>
                    {articleItem.tags.map((tag, index) =>
                        <span key={index} className={"tag"}>{tag}</span>
                    )}
                </div>
            }
            <Link to={articleItem.urls[category]}>
                <img className={"cover"} src={articleItem.img} alt={articleItem.title}/>
            </Link>
            <Link to={articleItem.urls[category]}>
                <p>
                    {articleItem.shortDescription(100)}
                </p>
            </Link>
        </div>
    );
}

export default ArticleStub;