import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeSlug from "rehype-slug";
import remarkToc from "remark-toc";
import rehypeAutolinkHeadings from "rehype-autolink-headings";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {duotoneDark as markdownColors} from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkBasePath from "../remarkBasePath";

export default class MarkdownView extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            markdown: ""
        }
    }

    componentDidMount() {
        if (this.state.markdown.length === 0) {
            fetch(this.props.src)
                .then(r => {
                    return r.text();
                })
                .then(text => this.setState({
                    markdown: text
                }))
        }
    }

    componentWillUnmount() {
        this.setState({
            markdown: ""
        })
    }

    render() {
        return (
            <div className={"markdown-view"} >
                <ReactMarkdown
                    children={this.state.markdown}
                    remarkPlugins={[
                        [remarkToc, {heading: "Contents"}],
                        remarkGfm,
                        [remarkBasePath, {basePath: "", baseImagePath: this.props.proxy}]
                    ]}
                    rehypePlugins={[
                        rehypeSlug,
                        rehypeAutolinkHeadings,
                        rehypeRaw
                    ]}
                    components={{
                        code({node, inline, className, children, ...props}) {
                            const match = /language-(\w+)/.exec(className || '')
                            return !inline && match ? (
                                <SyntaxHighlighter
                                    children={String(children).replace(/\n$/, '')}
                                    style={markdownColors}
                                    language={match[1]}
                                    PreTag="div"
                                    {...props}
                                />
                            ) : (
                                <code className={className} {...props}>
                                    {children}
                                </code>
                            )
                        }
                    }}
                />
            </div>
        );
    }
}