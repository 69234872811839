import _ from "lodash";

function urlJoin(parts) {
    let filtered = _.filter(parts, (part) => {
        return part !== "/" && part !== "";
    })
    let joined = filtered.map((part) => _.trim(part, '/')).join('/');

    if (_.startsWith(parts[0], '/')) {
        joined = '/' + joined;
    }

    if (parts.length > 1 && parts[parts.length-1] === "/") {
        joined += "/"
    }

    return joined;
}

export {urlJoin};