import {visitParents} from 'unist-util-visit-parents'
import {urlJoin} from "./util";

const isRelative = (str) => str.match(/^(?!.com|.co.uk)((?:\/?[A-Za-z0-9\-_~:?#[\]@!$&'()*+,;%=]*(?:.svg|.png|.jpg|.jpeg|.gif|.pdf)?)*)$/)

function remarkBasePath(options) {
    const basePath = options.basePath;
    const baseImagePath = options.baseImagePath;
    return (tree) =>
        visitParents(tree, ['image', 'link'], (node, parents) => {
            const match = isRelative(node.url)
            if (match) {
                const siblings = parents[parents.length - 1].children
                siblings[siblings.indexOf(node)] = {
                    ...node,
                    url: urlJoin([node.type === "image" ? baseImagePath || basePath : basePath,match[1]])
                }
            }
        })
}

export default remarkBasePath;