import React from "react";
import dataSource from "../dataSource";
import _ from "lodash";
import ArticleStub from "./ArticleStub";

export default class ArticleList extends React.Component {

    render() {
        return (
            <div id={"article-list"}>
                <h1>{_.capitalize(this.props.category)}s</h1>
                {dataSource.withCategory(this.props.category).map((articleItem, index) =>
                    <ArticleStub key={index} article={articleItem} category={this.props.category} />
                )}
            </div>
        );
    }
}
