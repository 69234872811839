import React from "react";

function Error(props) {
    return (
        <div className={"error-page"}>
            <h1>{props.msg}</h1>
        </div>
    );
}

export default Error;